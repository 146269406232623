import { useTranslation } from "react-i18next";
import Input from "../components/Inputs/Input";
import http from "../utils/http.utils";
import toastr from "../utils/toast.utils";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImageBackground from "../assets/images/bg-login.jpg";
import Logo from "../components/Logo";
import Loader from "../components/Loader";
import { useLocale } from "../context/LocaleContext";

export default function LoginPage() {
  const { t } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const returnURL = searchParams.get("returnBack");

  const [loading, setLoading] = useState(true);
  const [clientOauthUrl, setClientOauthUrl] = useState("#");
  const [sinfinOauthUrl, setSinfinOauthUrl] = useState("#");
  const [form, setForm] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [account, setAccount] = useState({
    email: "",
    password: "",
  });

  const backUrl = `${process.env.REACT_APP_FRONT_URL}/${locale}/login/after${
    returnURL ? `?returnBack=${encodeURIComponent(returnURL)}` : ""
  }`;

  const fetchMethods = async () => {
    setLoading(true);
    const data = await http.get("/api/auth-methods");
    data["hydra:member"].forEach((method) => {
      if ("oauth_client" === method.type) {
        setClientOauthUrl(
          `${method.url}?${method.options.backUrlQueryParameter}=${backUrl}`
        );
      } else if ("oauth_sinfin" === method.type) {
        setSinfinOauthUrl(
          `${method.url}?${method.options.backUrlQueryParameter}=${backUrl}`
        );
      } else if ("token_api" === method.type) {
        setForm(method);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchMethods();
  }, []);

  const onChange = (e) => {
    setAccount({ ...account, [e.target.id]: e.target.value });
  };

  const login = async (e) => {
    setFormLoading(true);
    e.preventDefault();
    try {
      const token = await http.post("/api/tokens", account);
      http.setToken(token.token);
      returnURL
        ? navigate(decodeURIComponent(returnURL))
        : navigate(`/${locale}/`);
    } catch (e) {
      toastr.error(t("wrong_account"));
    }
    setFormLoading(false);
  };

  return (
    <section
      className="h-full"
      style={{
        backgroundImage: `url(${ImageBackground})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex items-center justify-center h-full">
        <div className="bg-white p-10 pb-5 flex flex-col items-center justify-center gap-2 w-[400px]">
          <Logo />
          <h1 className="mb-10 uppercase font-lacosteExpandedBold">
            {t("app_name")}
          </h1>
          {loading ? (
            <Loader />
          ) : (
            <>
              <a
                href={clientOauthUrl}
                className="flex items-center justify-center w-full gap-2 py-4 mb-3 uppercase transition border hover:bg-slate-50 font-lacosteExpandedBold"
              >
                {t("login_client")}
              </a>
              {form ? (
                <>
                  <div className="flex items-center justify-center w-full font-lacosteExpandedBold">
                    <hr className="w-36" />
                    <span className="mx-2 uppercase">{t("or")}</span>
                    <hr className="w-36" />
                  </div>
                  <p className="mx-2 uppercase font-lacosteExpandedBold">for non-Lacoste SSO users</p>
                  <form
                    onSubmit={login}
                    className="flex flex-col w-full gap-4 font-lacosteExpandedBold"
                  >
                    <div>
                      <label htmlFor={form.options.emailParameter}>
                        {t("email")}
                      </label>
                      <Input
                        id={form.options.emailParameter}
                        type={"email"}
                        value={account.email}
                        onChange={onChange}
                        disabled={formLoading}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor={form.options.passwordParameter}>
                        {t("password")}
                      </label>
                      <Input
                        id={form.options.passwordParameter}
                        type={"password"}
                        value={account.password}
                        onChange={onChange}
                        disabled={formLoading}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="flex items-center justify-center w-full gap-2 py-2 uppercase transition border hover:bg-slate-50"
                    >
                      {t("login")}
                    </button>
                    <div className="flex justify-center w-full">
                      <Link
                        to={`/${locale}/forgot_password`}
                        className="text-sm hover:underline hover:cursor-pointer "
                      >
                        {t("forgot_password")}
                      </Link>
                    </div>
                  </form>
                </>
              ) : null}
              <a href={sinfinOauthUrl} className="m-4 text-xs text-secondary">
                {t("copyright", { year: new Date().getFullYear() })}
              </a>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
