import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header";
import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import DrawerMenu from "../components/DrawerMenu/DrawerMenu";
import Loader from "../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { getChildrens, getTree, hasChildrens } from "../utils/tree.utils";
import Breadcrumbs from "../components/Breadcrumbs";
import NavBloc from "../components/NavBloc";
import { useLocale } from "../context/LocaleContext";
import translateKey from "../utils/translateKey";
import iconPlay from "../assets/icons/picto-play.svg";
import { isChannel } from "../utils/isChannel";
import ModalFirstLogin from "../components/Modals/ModalFirstLogin";
import { useCategory } from "../context/CategoryProvider";

export default function Root() {
  const navigate = useNavigate();
  const locale = useLocale();
  const location = useLocation();
  const { t } = useTranslation();
  const { categories, getCategoryById } = useCategory();

  const searchParams = new URLSearchParams(window.location.search);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [navChilds, setNavChilds] = useState(null);
  const [currentNav, setCurrentNav] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [navChildsHasSubtitle, setNavChildsHasSubtitle] = useState(false);

  const currentNavID = +searchParams.get("nav");

  useEffect(() => {
    setCurrentNav(getCategoryById(currentNavID) ?? null);
  }, [categories, currentNavID]);

  useEffect(() => {
    if (categories) {
      if (currentNav) {
        if (!hasChildrens(currentNav, categories)) {
          navigate(`/${locale}/nav/${currentNav.id}`);
          return;
        }
        setNavChilds(getChildrens(currentNavID, categories));
      } else {
        setNavChilds(categories.filter((item) => item.parent === `/api/flat-content-categories/${process.env.REACT_APP_CX_ID}`));
      }
      handleBreadcrumb();
    }
  }, [categories, currentNav]);

  const handleBreadcrumb = () => {
    const bc = currentNav
      ? getTree(currentNav.id, null, categories).map((item) => ({
        link: `/?nav=${item.id}`,
        name: translateKey(item.names),
      }))
      : [];

    localStorage.setItem("breadcrumb", JSON.stringify(bc));
    setBreadcrumb(bc);
  };

  const handleNav = (item) => {
    if (!item) {
      navigate(`/${locale}/`);
      return;
    }

    if (isChannel(item)) {
      window.location.pathname = `/${locale}/nav/${item.id}`;
      return;
    }

    if (!hasChildrens(item, categories)) {
      if (item.color) {
        localStorage.setItem("color", item.color);
      } else if (!item.color && localStorage.getItem("color")) {
        localStorage.removeItem("color");
      }

      navigate(`/${locale}/nav/${item.id}`);
      return;
    }

    const params = new URLSearchParams(searchParams);

    if (item.breadcrumb.trail.split("/").length === 5) {
      navigate(`/${locale}/nav/${item.id}`);
      return;
    }

    if (params.has("nav")) {
      params.delete("nav");
    }
    params.set("nav", item.id);
    navigate(`/${locale}/?${params.toString()}`);
  };

  useEffect(() => {
    setNavChildsHasSubtitle(false);
    if (navChilds) {
      navChilds.forEach((item) => {
        if (item.values.category_subtitle?.[0].data && navChildsHasSubtitle === false) {
          setNavChildsHasSubtitle(true);
        }
      });
    }
  }, [navChilds, location.search]);

  return (
    <div className="flex flex-col h-full">
      <ModalFirstLogin />
      <Header openDrawer={openDrawer} toggleDrawer={() => setOpenDrawer(!openDrawer)} />
      <Banner />
      <div className="flex h-full overflow-hidden grow">
        <DrawerMenu isOpen={openDrawer} setIsOpen={setOpenDrawer} handleNav={handleNav} />
        <div className="flex flex-col h-full p-2 overflow-hidden grow">
          {breadcrumb.length > 0 && (
            <div className="flex items-end justify-between py-4 mb-4 border-b md:items-center">
              <div className="mb-2">
                <Breadcrumbs items={breadcrumb} showTitle />
              </div>
              {currentNav?.media && (
                <a
                  href={`${currentNav.media}?download=1`}
                  className={
                    "break-words flex flex-col justify-center w-10 h-10 md:w-20 md:h-20 items-center bg-primary hover:bg-primary-400 transition-all duration-200 text-white text-xs gap-2 rounded font-lacosteExpandedBold"
                  }
                >
                  <img src={currentNav.values?.category_link_icon?.[0].data?.url || iconPlay} alt="import" width={15} height={15} />
                  <span className="hidden uppercase md:block whitespace-break-spaces">
                    {currentNav.values?.category_link_name?.[0].data || t("instore_assets")}
                  </span>
                </a>
              )}
            </div>
          )}
          {navChilds ? (
            <div className={`grow flex flex-col gap-2 overflow-auto ${currentNavID ? "justify-start" : "justify-stretch"}`}>
              {navChilds.map((item) => {
                return (
                  <div key={item.id} className={currentNavID ? "" : "grow"}>
                    <NavBloc item={item} center={!navChildsHasSubtitle} onClick={() => handleNav(item)} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
