import { useTranslation } from "react-i18next";
import Carousel from "../Carousel";
import getMedias from "../../utils/getMedias";
import translateKey from "../../utils/translateKey";
import NavGridView from "./NavGridView";
import { useLocale } from "../../context/LocaleContext";
import { useParams } from "react-router-dom";

const ContentsView = ({ items, onClickItem }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const params = useParams();

  return (
    <div className="flex flex-col gap-4">
      {items?.length > 0 ? (
        items.map((contentGroup) => {
          if (!contentGroup.contents.length) {
            return (
              <section className="py-page" key={contentGroup.id}>
                <div className="flex flex-col gap-2 justify-between max-w-[97.3%] mx-auto">
                  <span className="text-xl uppercase font-lacosteExpandedBold px-page" style={{ color: "var(--primary)" }}>
                    {translateKey(contentGroup?.category?.names)}
                  </span>
                  <NavGridView
                    navs={contentGroup.children.map((c) => ({
                      ...c,
                      name: c.category.name || translateKey(c.category.names) || c.values.category_name[0].data,
                      goTo: `/${locale}/nav/${params.nav}?category=${c.category.id}`,
                    }))}
                  />
                </div>
              </section>
            );
          }

          return (
            <Carousel
              key={contentGroup.id}
              title={translateKey(contentGroup?.category?.names) ?? ""}
              medias={getMedias(contentGroup)}
              onClickItem={onClickItem}
            />
          );
        })
      ) : (
        <span> {t("no_contents")} </span>
      )}
    </div>
  );
};

export default ContentsView;
