import React from "react";
import ReactDOM from "react-dom/client";

import { Toaster } from "react-hot-toast";
import App from "./App";

import "./index.css";
import "./i18n/i18n";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="h-[100dvh] font-lacoste">
    <App />
    <Toaster />
  </div>,
);
