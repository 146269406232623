import { createContext, useContext, useEffect, useState } from "react";
import http from "../utils/http.utils";
import { USER_CONTEXT } from "../enums/USER_CONTEXT";

export const UserContext = createContext({});

/**
 * @typedef {{ id: number, email: string, isAdmin: undefined|boolean, isManager: undefined|boolean }} User
 * @returns {{ user: User, fetchUser: () => void }}
 */
export const useUser = () => useContext(UserContext);

export default function UserProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  // Logic for CX Survey platform
  const [isOpen, setIsOpen] = useState(false);

  const persistUser = async (u) => {
    setUser(u);
  };

  const fetchUser = async () => {
    try {
      const u = await http.get("/api/users/0");
      if (
        !u.context?.[USER_CONTEXT.FORM] &&
        u.context?.[USER_CONTEXT.FORM] !== process.env.REACT_APP_TEMP_FORM
      ) {
        setIsOpen(true);
      }
      await persistUser(u);
    } catch (error) {
      document.location.href = "/logout";
    }
  };

  const handleClickForm = async () => {
    try {
      await http.post("/api/user-contexts", {
        key: USER_CONTEXT.FORM,
        value: process.env.REACT_APP_TEMP_FORM,
      });

      await fetchUser();
      window.open(
        process.env.REACT_APP_TEMP_FORM,
        "_blank",
        "rel=noopener noreferrer",
      );
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  // calling user every 10 minutes (ensure token will be ok)
  useEffect(() => {
    const id = setInterval(load, 10 * 60 * 1000);

    return () => clearInterval(id);
  }, []);

  const load = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await fetchUser();
      setIsLoading(false);
    }
  };

  if (null === user) {
    void load();

    return null;
  }

  return (
    <UserContext.Provider value={{ user, fetchUser, setIsOpen, persistUser }}>
      {children}
      {/* {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <dialog
        open={isOpen}
        className="z-50 p-6 bg-white border rounded-lg w-96 top-1/4"
      >
        <div className="flex items-start justify-between mb-2">
          <h3 className="text-lg">
            Your feedback matters!
            <br />
            CX Platform survey
          </h3>
          <button type="button" onClick={() => setIsOpen(false)}>
            X
          </button>
        </div>
        <p>
          Please take five minutes of your time (no more, we promise) to fill
          our survey and help us improve the CX platform.
        </p>
        <div className="flex justify-end gap-2">
          <button
            type="button"
            onClick={handleClickForm}
            className="text-white bg-primary border border-primary focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 me-2 mt-3 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
          >
            Take the survey
          </button>
        </div>
      </dialog> */}
    </UserContext.Provider>
  );
}
