import { createContext, useContext, useState } from "react";
import http from "../utils/http.utils";
import Loader from "../components/Loader";
import { getChildrens } from "../utils/tree.utils";

const CategoryContext = createContext({});

export const useCategory = () => useContext(CategoryContext);

export default function CategoryProvider({ children }) {
  const [categories, setCategories] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetchCategory = async () => {
    setIsLoading(true);
    try {
      const data = await http.get("/api/flat-content-categories", {
        pagination: false,
        breadcrumb: `${process.env.REACT_APP_CX_ROOT_ID}/${process.env.REACT_APP_CX_ID}`,
      });
      setCategories(data["hydra:member"]);
    } catch (error) {
      setCategories(null);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader />
      </div>
    );
  }

  if (null === categories) {
    void fetchCategory();

    return null;
  }
  
  const getCategoryById = (id) => categories?.find((category) => category.id === id) || null;

  const tabs = categories
    .filter((item) => item.parent === `/api/flat-content-categories/${process.env.REACT_APP_CX_ID}`)
    .map((item) => ({
      ...item,
      items: getChildrens(item.id, categories),
    }));

  return <CategoryContext.Provider value={{ categories, getCategoryById, tabs }}>{children}</CategoryContext.Provider>;
}
