const PERMISSION_GRANTED = "granted";

export default class NotificationUtils {

    static isEnabled() {
        return "Notification" in window;
    }

    static hasPermission() {
        if (this.isEnabled()) {
            return PERMISSION_GRANTED === Notification.permission;
        } else {
            console.error("notification not enabled in window");
        }

        return false;
    }

    static async requestPermission() {
        if (this.isEnabled()) {
            return PERMISSION_GRANTED === await Notification.requestPermission();
        } else {
            console.error("notification not enabled in window");
        }

        return false;
    }

    static open(title, body) {
        if (this.isEnabled()) {
            new Notification(title, {
                icon: "/icon.png", body,
            });
        }
    }

}
