import http from "../utils/http.utils";
import { isDesktop } from "../utils/style.utils";
import Breadcrumbs from "../components/Breadcrumbs";
import Header from "../components/ContentCategory/Header";
import Loader from "../components/Loader";
import MediaModal from "../components/MediaModal/MediaModal";
import Navbar from "../components/Navbar/Navbar";
import ContentsView from "../components/Views/ContentsView";
import GridContentsView from "../components/Views/GridContentsView";
import NavGridView from "../components/Views/NavGridView";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocale } from "../context/LocaleContext";
import translateKey from "../utils/translateKey";
import ButtonTutorial from "../components/ButtonTutorial";
import ButtonFilesCategories from "../components/ButtonFilesCategories";
import { useCategory } from "../context/CategoryProvider";

export default function ContentCategoryPage() {
  const navigate = useNavigate();
  const locale = useLocale();
  const { categories } = useCategory();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [category, setCategory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(false);
  const [leaf, setLeaf] = useState(null);

  const [isLoading, setIsLoading] = useState({
    categories: true,
    nav: true,
    leaf: true,
  });

  const nav = categories?.find((item) => item.id === +params.nav);

  const maxSegments = 5;
  const trailSegments = nav.breadcrumb.trail.split("/").slice(0, maxSegments);
  const filteredTrail = trailSegments.join("/");

  // Not the right way, temp way until attributes logic is done in the platform side
  const contentNav = categories.find((item) => item.id === Number(trailSegments[4]));

  const categoriesFiltered = categories.filter((item) => item.breadcrumb.trail.startsWith(filteredTrail) && item.id !== nav.id);

  const categoryId = searchParams.get("category") || params.nav;

  const fetchLeaf = async (id) => {
    setIsLoading((prev) => ({ ...prev, leaf: true }));
    const data = await http.get(`/api/leaves/${id}`);
    setLeaf(data);
    setCategory(data.category);
    setIsLoading((prev) => ({ ...prev, leaf: false }));
  };

  useEffect(() => {
    if (nav.breadcrumb.trail.split("/").length === 5 && !searchParams.get("category")) {
      if (!categoriesFiltered?.[0]?.id) {
        navigate(`/${locale}/nav/${params.nav}`);
        return;
      }
      searchParams.set("category", categoriesFiltered?.[0]?.id);
      navigate(`/${locale}/nav/${params.nav}?category=${categoriesFiltered?.[0]?.id}`);
    }
  }, [params.nav]);

  // Old way kept for regression tests
  // useEffect(() => {
  //   if (!categoryId && categoriesFiltered?.length > 0) {
  //     const firstParent = categoriesFiltered.find((category) => nav && nav["@id"] === category.parent);

  //     if (getChildrens(firstParent.id, categoriesFiltered)?.length > 0) {
  //       const id = getChildrens(firstParent.id, categoriesFiltered)[0].id;
  //       navigate(`/${locale}/nav/${params.nav}?category=${id}`);
  //     } else {
  //       navigate(`/${locale}/nav/${params.nav}?category=${firstParent.id}`);
  //     }
  //   }
  // }, [categoriesFiltered]);

  useEffect(() => {
    setView(null);
    if (searchParams.get("category")) {
      fetchLeaf(searchParams.get("category"));
      return;
    }
    if (!searchParams.get("category") && categoryId) {
      fetchLeaf(categoryId);
    }
  }, [searchParams.get("category"), categoryId]);

  useEffect(() => {
    if (leaf) {
      getView(leaf);
    }
  }, [leaf]);

  const [view, setView] = useState(null);

  const enumViewType = {
    CONTENTS: "CONTENTS",
    CONTENT_CHILDREN: "CONTENT_CHILDREN",
    NAVIGATE_CHILDREN: "NAVIGATE_CHILDREN",
    GRID_CONTENTS: "GRID_CONTENTS",
    GRID_CONTENTS_GROUPED: "GRID_CONTENTS_GROUPED",
  };

  const getView = (leaf) => {
    // Vue groupé des contenus de type template_4
    if (leaf.children.some((c) => c.contents?.length > 0)) {
      if (leaf.children[0].contents[0]?.attributeGroup === "template_4" || nav?.values?.category_channel?.[0]?.data === "1") {
        setView(enumViewType.GRID_CONTENTS_GROUPED);
        return;
      }
    }

    if (leaf.contents?.length > 0) {
      if (leaf.contents[0].attributeGroup === "template_4") {
        setView(enumViewType.GRID_CONTENTS);
      } else {
        setView(enumViewType.CONTENTS);
      }
    } else if (leaf.children?.length > 0) {
      if (!isDesktop()) {
        setView(enumViewType.NAVIGATE_CHILDREN);
      } else {
        setView(enumViewType.CONTENT_CHILDREN);
        if (nav.breadcrumb.trail.split("/").length === 5) {
          return;
        }
        if (leaf.children[0].children?.length > 0) {
          setView(enumViewType.NAVIGATE_CHILDREN);
        } else {
          setView(enumViewType.CONTENT_CHILDREN);
        }
      }
    }
  };

  const handleNavBack = () => {
    navigate(`/${locale}/?nav=${nav.parent.split("/").pop()}`);
  };

  const breads = leaf?.category?.breadcrumb.parts.filter((b) => b.id !== +process.env.REACT_APP_CX_ROOT_ID && b.id !== +process.env.REACT_APP_CX_ID);

  const items =
    view === enumViewType.CONTENTS
      ? [{ contents: leaf.contents }]
      : view === enumViewType.GRID_CONTENTS
      ? leaf.contents
      : view === enumViewType.CONTENT_CHILDREN
      ? leaf?.children?.map((child) => ({
          contents: child.contents,
          title: translateKey(child.category.names),
        }))
      : leaf?.children?.map((c) => ({
          ...c,
          name: c.category.name || translateKey(c.category.names) || c.values.category_name[0].data,
          goTo: `/${locale}/nav/${params.nav}?category=${c.category.id}`,
        }));

  return (
    <div className="flex flex-col h-full">
      {!categoriesFiltered || !nav ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <div id="header-container">
          <Header title={translateKey(nav.names)} handleBack={handleNavBack} />
          {/* Nav for basic contents like chapters else nav for VM Library */}
          <Navbar nav={contentNav || nav} items={categoriesFiltered} route="nav" />
          <div className="flex flex-col py-2 px-page md:flex-row md:justify-between md:items-end">
            <div className="mb-2">{breads ? <Breadcrumbs items={breads} showTitle /> : null}</div>
            <div className="flex items-center gap-2">
              {category?.values?.category_link?.[0].data && (
                <ButtonTutorial
                  href={category?.values?.category_link?.[0].data}
                  icon={category?.values?.category_link_icon?.[0].data?.url}
                  label={category?.values?.category_link_name?.[0].data}
                />
              )}
              <ButtonFilesCategories category={category} />
            </div>
          </div>
        </div>
      )}
      <div className="grow overflow-auto z-[1]">
        {!isLoading.leaf && view && (
          <>
            {view === enumViewType.CONTENTS && <ContentsView items={[{ contents: leaf.contents }]} onClickItem={setCurrentIndex} />}
            {view === enumViewType.CONTENT_CHILDREN && <ContentsView items={leaf.children} onClickItem={setCurrentIndex} />}
            {view === enumViewType.GRID_CONTENTS && <GridContentsView leaf={leaf} />}
            {view === enumViewType.GRID_CONTENTS_GROUPED &&
              leaf.children.map((child) => {
                return (
                  <Fragment key={child.id}>
                    <div>
                      <h2 className="p-2 text-2xl font-lacosteExpandedBold text-primary">{translateKey(child.category.names)}</h2>
                      <GridContentsView leaf={child} />
                    </div>
                    <hr />
                  </Fragment>
                );
              })}
            {view === enumViewType.NAVIGATE_CHILDREN && (
              <NavGridView
                navs={leaf.children.map((c) => ({
                  ...c,
                  name: c.category.name || translateKey(c.category.names) || c.values.category_name[0].data,
                  goTo: `/${locale}/nav/${params.nav}?category=${c.category.id}`,
                }))}
              />
            )}
          </>
        )}
      </div>
      {currentIndex !== false ? (
        <MediaModal currentIdx={currentIndex} items={items} isOpen={false !== currentIndex} onClose={() => setCurrentIndex(false)} />
      ) : null}
    </div>
  );
}
