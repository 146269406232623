import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import translateKey from "../utils/translateKey";

export const NavBloc = ({ item, center = true, onClick, className }) => {
  const { goTo, names, name, color, values } = item;
  const link = values?.category_link?.[0]?.data || null;
  const subtitle = values?.category_subtitle?.[0].data || null;
  const icon = values?.category_icon?.[0].data.url ? values.category_icon[0].data.url : null;
  const navigate = useNavigate();
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (link) {
      window.open(link, "_blank");
    } else if (goTo) {
      navigate(`${goTo}`);
    }
  };

  const getAlign = () => {
    if (subtitle) {
      return "justify-between";
    }
    if (center) {
      return "justify-center";
    }
    return "";
  };

  return (
    <div className={`bg-primary h-full cursor-pointer ${className}`} onClick={handleClick} style={color ? { backgroundColor: color } : {}}>
      <div
        className={`w-full h-full transition scale-90 lg:hover:scale-100 duration-100 flex flex-col md:flex-row items-center ${
          !icon && "p-10"
        } ${getAlign()}`}
      >
        {icon ? (
          <img src={icon} alt={translateKey(names)} className="h-28" />
        ) : (
          <span className="text-lg text-center text-white uppercase font-lacosteExpandedBold md:text-3xl">{translateKey(names) || name}</span>
        )}

        {subtitle && <span className="text-white uppercase text-md ">{subtitle}</span>}
      </div>
    </div>
  );
};

NavBloc.propTypes = {
  item: PropTypes.shape({
    subtitle: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default NavBloc;
