import icon_notification from "../../assets/icons/picto-notification.svg";
import Dropdown from "../../components/Dropdown/Dropdown";
import http from "../../utils/http.utils";
import { useEffect, useState } from "react";
import HeaderButton from "./HeaderButton";
import Notification from "./Notification";
import { useTranslation } from "react-i18next";
import NavigatorUtils from "../../utils/NavigatorUtils";
import { useUser } from "../../context/UserProvider";

export default function Notifications() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [notifications, setNotifications] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const [notificationsToShow, setNotificationsToShow] = useState(null);
  const [tabActive, setTabActive] = useState(1);
  const [hasSubscription, setHasSubscription] = useState(false);

  const fetchNotifications = async () => {
    const data = await http.get("/api/notifications");
    setNotifications(data["hydra:member"]);
    if (data["hydra:member"]) {
      const unreadNotifications = data["hydra:member"].filter((n) => !n.readAt);
      setUnreadNotifications(unreadNotifications);
      void NavigatorUtils.setAppBadge(unreadNotifications.length);
    }
  };

  const fetchSubscription = async () => {
    setHasSubscription(await NavigatorUtils.isSubscribed());
  };

  useEffect(() => {
    void fetchNotifications();
    void fetchSubscription();
  }, []);

  useEffect(() => {
    if (1 === tabActive) {
      setNotificationsToShow(unreadNotifications);
    } else {
      setNotificationsToShow(notifications);
    }
  }, [tabActive, notifications]);

  const onClickNotification = async (e, notification) => {
    notification.readAt = notification.readAt ? null : new Date().toISOString();

    await http.put(`/api/notifications/${notification.id}`, notification);

    void fetchNotifications();
  };

  const notificationsCount = notifications?.length > 0 ? notifications.length : 0;
  const unreadNotificationsCount = unreadNotifications?.length > 0 ? unreadNotifications.length : 0;

  const handleNotificationSubscription = async () => {
    try {
      // trigger service worker registration (notifications)
      await NavigatorUtils.register();
    } catch (e) {
    } finally {
      void fetchSubscription();
    }
  };

  return (
    <>
      {false === hasSubscription ? (
        <button type="button" className="p-2" onClick={handleNotificationSubscription}>
          Enable device notifications
        </button>
      ) : null}
      <Dropdown
        button={
          <HeaderButton src={icon_notification} count={unreadNotificationsCount} />
        }
        items={notificationsToShow?.map((notification, i) => ({
          id: `notification-${notification.id}`,
          content: <Notification index={i} notification={notification} />,
          onClick: (e) => {
            onClickNotification(e, notification);
          },
        }))}
        noItemText={t("no_notification")}
      >
        <div className="flex flex-col gap-2 m-2 p-2">
          <h1 className="uppercase text-primary font-lacosteExpandedBold font-bold text-xl">
            Notifications
          </h1>
          <div className="flex items-center gap-4">
            <button
              type="button"
              className={`font-lacosteExpandedBold transition uppercase ${
                tabActive === 1 ? "text-primary " : "text-gray-300"
              }
                    `}
              onClick={() => setTabActive(1)}
            >
              {t("new")} {`(${unreadNotificationsCount})`}
            </button>
            <button
              type="button"
              className={`font-lacosteExpandedBold transition uppercase ${
                tabActive === 2 ? "text-primary " : "text-gray-300"
              }
                    `}
              onClick={() => setTabActive(2)}
            >
              {t("all")} {`(${notificationsCount})`}
            </button>
          </div>
        </div>
      </Dropdown>
    </>
  );
}
