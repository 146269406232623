import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Root from "./pages/Root";
import LoginPage from "./pages/Login";
import LoginAfterPage from "./pages/LoginAfter";
import LogoutPage from "./pages/Logout";
import ProfilePage from "./pages/Profile";
import ContentCategoryPage from "./pages/ContentCategory";
import ForgotPasswordPage from "./pages/ForgotPassword";
import { LocaleProvider } from "./context/LocaleContext";
import NotFound from "./pages/NotFound";
import { useEffect } from "react";
import localeUtils from "./utils/locale.utils";
import SlugPage from "./pages/Slug";
import CalendarsPage from "./pages/Calendars";
import Pdf from "./components/Pdf/pdf";
import ResetPasswordPage from "./pages/ResetPassword";
import http from "./utils/http.utils";
import UserProvider from "./context/UserProvider";
import StatsPage from "./pages/StatsPage";
import Users from "./pages/Users";
import CategoryProvider from "./context/CategoryProvider";
// import LangSwitcher from "./components/LangSwitcher";

const App = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const PrivateRoutes = () => {
    const token = http.getToken();
    if (!token) {
      return <Navigate to={`/${locale}/login?returnBack=${encodeURIComponent(window.location.pathname + window.location.search)}`} />;
    }

    return (
      <UserProvider>
        <CategoryProvider>
          <Outlet />
        </CategoryProvider>
      </UserProvider>
    );
  };

  useEffect(() => {
    localeUtils.setLocale(locale);
  }, [locale]);

  return (
    <LocaleProvider locale={locale}>
      {/* <LangSwitcher /> */}
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path={`/${locale}/login`} element={<LoginPage />} />
          <Route path={`/${locale}/login/after`} element={<LoginAfterPage />} />
          <Route path={`/${locale}/logout`} element={<LogoutPage />} />
          <Route path={`/${locale}/logout-force`} element={<LogoutPage />} />
          <Route path={`/${locale}/forgot_password`} element={<ForgotPasswordPage />} />
          <Route path={`/${locale}/forgot_password/:token`} element={<ResetPasswordPage />} />
          <Route path={`/${locale}/pdf`} element={<Pdf />} />
          {/* Private Routes */}
          <Route element={<PrivateRoutes />}>
            <Route path={`/${locale}/`} element={<Root />} />
            <Route path={`/${locale}`} element={<Root />} />
            <Route path={`/${locale}/calendars`} element={<CalendarsPage />} />
            <Route path={`${locale}/:slug`} element={<SlugPage />} />
            <Route path={`/${locale}/profile`} element={<ProfilePage />} />
            <Route path={`/${locale}/stats`} element={<StatsPage />} />
            <Route path={`/${locale}/users`} element={<Users />} />
            <Route path={`${locale}/nav/:nav`} element={<ContentCategoryPage />} />
          </Route>
        </Routes>
      </Router>
    </LocaleProvider>
  );
};

export default App;
