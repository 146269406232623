import NotificationUtils from "./NotificationUtils";
import http from "./http.utils";

const SCRIPT_NAME = "/service-worker.js";

export default class NavigatorUtils {

  static isEnabled() {
    return "serviceWorker" in navigator;
  }

  static async isSubscribed() {
    return null !== await this.getSubscription();
  }

  static async getRegistration() {
    if (!this.isEnabled()) {
      return null;
    }

    return await navigator.serviceWorker.register(SCRIPT_NAME);
  }

  static async getSubscription() {
    if (!this.isEnabled()) {
      return null;
    }

    const registration = await this.getRegistration();

    return await registration.pushManager.getSubscription();
  }

  static async register() {
    if (this.isEnabled()) {
      try {
        let subscription = await this.getSubscription();
        if (subscription) {
          // @todo allow users to unsubscribe
          void subscription.unsubscribe();
        } else {
          const applicationServerKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
          const registration = await this.getRegistration();
          const subscription = await registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey });
          try {
            await http.post("/api/subscriptions", { signature: subscription });
          } catch (e) {
            console.error("service worker subscription error", e);

            void subscription.unsubscribe();
          }
        }
      } catch (e) {
        console.error("service worker registration error", e);
      }
    } else {
      console.debug("service worker not supported in navigator");
    }
  }

  static async open(title, body) {
    if (this.isEnabled()) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage({
          type: "NOTIFICATION", title, body,
        });
      }).catch(() => {
        NotificationUtils.open(title, body);
      });
    } else {
      NotificationUtils.open(title, body);
    }
  }

  static async setAppBadge(badge) {
    if (navigator && navigator.setAppBadge && navigator.clearAppBadge) {
      if (badge) {
        void navigator.setAppBadge(badge);
      } else {
        void navigator.clearAppBadge();
      }
    }
  }

}
