import { useTranslation } from "react-i18next";
import http from "../utils/http.utils";
import { useEffect, useState } from "react";
import translateKey from "../utils/translateKey";

export default function Banner() {
  const { t } = useTranslation();
  const [messages, setMessages] = useState(null);

  const fetchMessages = async () => {
    const data = await http.get("/api/flat-contents", {
      pagination: false,
      breadcrumb: `${process.env.REACT_APP_CX_ROOT_ID}/${process.env.REACT_APP_MESSAGES_FOLDER_ID}`,
    });
    setMessages(data["hydra:member"]);
  };

  useEffect(() => {
    void fetchMessages();
  }, []);

  return (
    <div className="shrink-0 overflow-hidden bg-primary p-1 w-full whitespace-nowrap">
      <div className="flex justify-between items-center w-full relative h-12 py-2 px-0 h-[calc(2rem+16px)]">
        <div className="w-fit inline-flex absolute text-white text-[0.8rem] animate-defilement">
          {messages ? (
            messages.map((msg, i) => (
              <div
                key={msg.id}
                className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl whitespace-nowrap"
              >
                <span className="whitespace-nowrap">
                  {translateKey(msg.names)}
                </span>
                {i < messages.length - 1 && <span> | </span>}
              </div>
            ))
          ) : (
            <div className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl">
              <span>{t("app_name")} | No message to show</span>
            </div>
          )}
        </div>
        <div
          className={
            "hidden xl:inline-flex w-fit inline-flex absolute text-white text-[0.8rem] animate-defilement2"
          }
        >
          {messages ? (
            messages.map((msg, i) => (
              <div
                key={msg.id}
                className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl whitespace-nowrap"
              >
                <span className="whitespace-nowrap">
                  {translateKey(msg.names)}
                </span>
                {i < messages.length - 1 && <span> | </span>}
              </div>
            ))
          ) : (
            <div className="flex items-center m-2 gap-2 float-left bg-transparent text-white font-lacosteExpandedBold break-normal md:text-xl">
              <span>{t("app_name")} | No message to show</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
